import React from 'react'
import { Link } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer, MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact'
import Img from 'gatsby-image'

class ServerBrands extends React.Component {
  render() {
    return (
      <section className="bg-white-grey-gradient text-center">
        <MDBContainer className="text-center">
          <h2 className={this.props.range == "UEI" ? 
            "font-alt font-w-700 letter-spacing-1 mb-3 title-xs-large title-large pb-3 text-left" :
            "font-alt font-w-700 letter-spacing-1 mb-3 title-xs-large title-large pb-3"}
          >
            {this.props.title}
          </h2>
          {this.props.subtitle && (
            <div className="pb-5 font-w-400 text-medium">
              {this.props.subtitle}
            </div>
          )}
          <MDBRow>
            {this.props.brands.map(({ node }, index) => (
              <>
                {((this.props.range && node.frontmatter.brandrange == this.props.range) || !this.props.range) && (
                  <MDBCol md={index < 3 ? "4" : "6"} className={(this.props.range == "Dell EMC" || this.props.range == "Hewlett Packard Enterprise") ? "offset-md-3 py-3" : "py-3"} key={index}>
                    <MDBCard>
                      <div style={index < 3 ? { minHeight: '520px',maxHeight: '520px' } : { minHeight: '315px',maxHeight: '315px' }}>
                        <MDBCardTitle className="text-center title-extra-small font-w-700 pt-3" style={{minHeight: "60px", maxHeight: "60px"}}>
                          <Img
                            fixed={node.frontmatter.brandlogo.childImageSharp.fixed}
                            className="m-3"
                            alt={node.frontmatter.alttext}
                          />
                        </MDBCardTitle>
                        <MDBCardBody>
                          <div
                            className="text-card-small font-w-400"
                            style={ index < 3 ? { minHeight: '175px', maxHeight: '175px' } : { minHeight: '105px', maxHeight: '105px' } }
                          >
                            {node.frontmatter.branddescription}
                          </div>
                          <hr />
                          <MDBRow>
                            {node.frontmatter.section.map((sections, index) => (
                              <MDBCol className="px-2" key={index}>
                                <Link
                                  to={sections.link}
                                  className="nav-link btn btn-mdb-color mr-0 mt-xl-3"
                                  style={{backgroundColor: 'rgb(41,83,149)', color: '#fff', width: '100%'}}
                                  target="_blank"
                                >
                                  {sections.linktext}
                                </Link>
                              </MDBCol>
                            ))}
                          </MDBRow>
                        </MDBCardBody>
                      </div>
                    </MDBCard>
                  </MDBCol>
                )}
              </>
            ))}
          </MDBRow>
        </MDBContainer>
    </section>
    )
  }
}

export default ServerBrands